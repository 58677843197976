<template>
  <div>
    <div>
      <h2 class="app-heading mb-4">Suppliers Directory</h2>
      <p class="text-muted mb-2">Your Travelify application can connect to a variety of third party suppliers and integrations to extend the range of products and functionality. You will need to have an account set up with each supplier/integration before you connect them</p>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2 lg:w-3/4">
      <div class="flex flex-wrap justify-start">
          <button 
            v-for="(productType, index) in allProductTypes" 
            :key="index" 
            @click="filterProductType(productType.id)" 
            type="button" 
            class="mr-2 mt-2 vs-component vs-button vs-button-primary"
            :class= "{'vs-button-filled': activeProductTypeId === productType.id, 'vs-button-border': activeProductTypeId !== productType.id}">
            <span class="vs-button-backgroundx vs-button--background" style="opacity: 1; left: 20px; top: 20px; width: 0px; height: 0px; transition: width 0.3s ease 0s, height 0.3s ease 0s, opacity 0.3s ease 0s;"></span>
            <span class="vs-button-text vs-button--text">{{productType.name}}</span>
            <span class="vs-button-linex" style="top: auto; bottom: -2px; left: 50%; transform: translate(-50%);"></span>
          </button>
        </div>
      </div>
      <div class="vx-col w-full md:w-1/2 lg:w-1/4 lg:pl-0 mb-4">
        <vs-input icon-pack="feather" icon="icon-search" placeholder="Search" v-model="searchText" @input="activeProductTypeId=0" class="w-full mt-2"/>
      </div>
    </div>
    <div class="vx-row mt-4 match-height">
      <div v-for="(supplierIntegration, index) in filteredSupplierIntegrations" :key="index" class="vx-col w-full md:w-1/2 lg:w-1/3 xl:w-1/4 mt-4">
        <supplier-integrations-card :supplierIntegration="supplierIntegration" :applicationId="applicationId"/>
      </div>
    </div>
  </div>
</template>

<script>

import SupplierIntegrationsCard from './SupplierIntegrationsCard.vue'

export default {
  props: {
    applicationId: { required: true }
  },
  components: {
    SupplierIntegrationsCard
  },
  data () {
    return {
      activeProductTypeId: 0,
      searchText: null,
      productTypes: [],
      allIntegrations: []
    }
  },
  computed: {
    filteredSupplierIntegrations () {
      if (this.searchText) {
        const searchText = this.searchText.toLowerCase()
        return this.allIntegrations
          .filter(integration => {
            return (integration.name && integration.name.toLowerCase().includes(searchText))
              || (integration.description && integration.description.toLowerCase().includes(searchText))
          })
          .sort((first, second) => (first.name.toLowerCase() > second.name.toLowerCase()) ? 1 : -1)
      }

      if (this.activeProductTypeId > 0) {
        const activeProductType = this.productTypes.find(x => x.id == this.activeProductTypeId)
        return this.allIntegrations
          .filter(x => activeProductType.supplierIntegrationIds.some(y => y == x.id))
          .sort((first, second) => (first.name.toLowerCase() > second.name.toLowerCase()) ? 1 : -1)
      }
      
      return this.allIntegrations
        .sort((first, second) => (first.name.toLowerCase() > second.name.toLowerCase()) ? 1 : -1)

    },
    allProductTypes () {
      return [{id: 0, name: 'All Products', supplierIntegrationIds: [] }, ...this.productTypes ]
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  mounted () {
    
    //Check if user has acccess to the company
    const hasAccess = this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)
    if(!hasAccess) {
      this.$router.push('/error-404')
      return
    }

    this.$vs.loading()
    this.$http.get(`api/supplierIntegrations/apps/${this.applicationId}`)
    .then(response => {
        this.allIntegrations = response.data.suppliers
        this.productTypes = response.data.productTypes
    })
    .catch(error => console.error(error))
    .finally(() => this.$vs.loading.close())  
  },
  methods: {
    filterProductType (productTypeId) {
      this.searchText = null
      this.activeProductTypeId = productTypeId
    }
   
  }
}
</script>

<style scoped lang="scss">
.app-heading {
  color: #636363
}
</style>