<template>
  <vx-card style="cursor: pointer" @click="openSupplierIntegration(supplierIntegration.id)">
    <div class="flex flex-wrap items-center">
      <vs-avatar 
        :src="supplierIntegration.icon"
        :text="supplierIntegration.name" 
        class="supplier-logo-avatar mr-2" 
        size="50px"
      />
      <div class="">
        <div class="flex items-center justify-start">
          <h5 class="ml-2 mr-2">{{supplierIntegration.name}}</h5>
          <vs-chip v-if="supplierIntegration.isPremium" color="warning"><span class="text-xs">P</span></vs-chip>
        </div>
        <p class="ml-2 mt-1 text-sm">
          <span>{{supplierIntegration.productType}} &#8226; {{supplierIntegration.apiType}}</span>
        </p>
      </div>
      
    </div>
    <p class="mt-2 text-sm supplier-pricing">
      <span> Setup Price: </span>
      <span class="">&pound; {{supplierIntegration.setupPrice.toFixed(2)}}</span>
       | 
      <span> Monthly: </span>
      <span class="text-sm">&pound; {{supplierIntegration.monthlyPrice.toFixed(2)}}</span>
    </p>
    <p class="mt-2">{{strippedDescription}}</p>
   
  </vx-card>
</template>

<script>

export default {
  props: {
    applicationId: { required: true },
    supplierIntegration: { required: true }
  },
  data () {
    return {
    }
  },
  computed: {
    strippedDescription () {
      let regex = /(<([^>]+)>)/ig;
      return this.supplierIntegration.description && 
        this.supplierIntegration.description.replace(regex, "")
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    openSupplierIntegration (supplierId) {
      this.$router.push({ name: 'supplier-integrations-details', 
        params: { 
          supplierId: supplierId,
          applicationId: this.applicationId
        }
      })
    },
    setAltImgForThumbnail (event) {
      const altText = encodeURIComponent(this.supplierIntegration.name)
      event.target.src = `https://dummyimage.com/1280X720/009687/ffffff.png&text=${altText}`
    },
    setAltImgForIcon (event) {
      const altText = this.supplierIntegration.name && this.supplierIntegration.name.charAt(0)
      event.target.src = `https://dummyimage.com/32x32/009687/ffffff.png&text=${altText}`
    }
  }
}
</script>
<style lang="scss" scpoped>
  .supplier-logo-avatar {
    .con-vs-avatar {
      border-radius: 10%;
    }
    .vs-avatar--con-img {
      border-radius: 10%;
    }
  }

  .supplier-pricing {
    color: rgba(var(--vs-primary));
  }
</style>